import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import NavBar from "../components/NavBar";

const Electricity = ({
  setCurrentPage,
  inputValues,
  modifyInputValue,
  data,
  currentPage
}) => {
  function electricityPageCO2Calculator() {
    const electricityMultipiler = data.OneElectricityInputToCO2InKG;
    const gasMultipiler = data.OneGasInputToCO2InKG;
    const currentElectricity = inputValues["Electricity"];
    const currentGas = inputValues["Gas"];
    return (
      electricityMultipiler * currentElectricity + gasMultipiler * currentGas
    );
  }
  return (
    <div className="left">
      <h1 className="main-title">Karbonlábnyom kalkulátor</h1>
      <NavBar currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <hr className="nav-hr" />
      <div className="dynamic-page">
        <h1 className="page-title">Éves Energiafogyasztás</h1>
        <div className="electricyt-page-input-contanier">
          <div className="electricity-page-input-box">
            <p className="dynamic-page-input-title">VILLAMOSENERGIA</p>
            <div className="electricity-page-input-row">
              <input
                type="number"
                value={inputValues["Electricity"]}
                onChange={(e) =>
                  modifyInputValue("Electricity", e.target.value)
                }
              />
              <p className="dynamic-page-input-end">kWH</p>
            </div>
          </div>
          <div className="electricity-page-input-box">
            <p className="dynamic-page-input-title">GÁZ</p>
            <div className="electricity-page-input-row">
              <input
                type="number"
                value={inputValues["Gas"]}
                onChange={(e) => modifyInputValue("Gas", e.target.value)}
              />
              <p className="dynamic-page-input-end">
                m<span>&#179;</span>
              </p>
            </div>
          </div>
        </div>
        <p className="dynamic-page-data">
          <span style={{ textAlign: "left" }}>CO₂:</span>
          <span style={{ fontWeight: "600", textAlign: "right" }}>
            {Math.ceil(electricityPageCO2Calculator())} KG
          </span>
        </p>{" "}
        <p className="dynamic-page-data">
          <span style={{ textAlign: "left" }}>Megkötés Költsége: </span>
          <span style={{ fontWeight: "600", textAlign: "right" }}>
            {Math.ceil(electricityPageCO2Calculator() * data.OneKgCO2ToHUF)} FT
          </span>
        </p>
      </div>
      <div
        className="page-switching-buttons"
        style={{ justifyContent: "right" }}
      >
        <button onClick={() => setCurrentPage("Közlekedés")}>
          Következő
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};

export default Electricity;
