import React from 'react';

const TotalElement = ({ inputValues, inputValueName, text, co2ToHUF,inputToCo2 }) => {
  return (
    inputValues[inputValueName] > 0 ? (
      <p className="total-element">
        <span style={{ textAlign: "left" }}>{text}</span>{" "}
        <span style={{ textAlign: "left" }}>
          {Math.ceil(
            (inputValues[inputValueName]* inputToCo2)* co2ToHUF
          )} FT
        </span>
      </p>
    ) : null
  );
};

export default TotalElement;
