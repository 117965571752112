import { useState } from "react";
import NavBar from "./components/NavBar";
import PageHandler from "./components/PageHandler";
import data from "./data.json"

function App() {
  const [currentPage,setCurrentPage] = useState("Energia")
  return (
    <div className="App">
      <PageHandler currentPage={currentPage} setCurrentPage={setCurrentPage} data={data}/>
    </div>
  );
}

export default App;
