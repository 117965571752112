import React, { useState } from "react";
import Energy from "../pages/Energy";
import Travel from "../pages/Travel";
import Total from "./Total";

const PageHandler = ({ currentPage, setCurrentPage, data }) => {
  const [inputValues, setInputValues] = useState({
    Electricity: "",
    Gas: "",
    Train: "",
    Air: "",
    Gasoline: "",
    Diesel: "",
  });
  const [gasolineAvrageLiter, setGasolineAvrageLiter] = useState("");
  const [dieselAvrageLiter, setDieselAvrageLiter] = useState("");
  function modifyInputValue(inputValueName, newValue) {
    if (newValue > 0 && newValue < 1000001) {
      setInputValues((prevState) => ({
        ...prevState,
        [inputValueName]: newValue,
      }));
    } else if (newValue <= 0) {
      setInputValues((prevState) => ({
        ...prevState,
        [inputValueName]: "",
      }));
    }
  }
  function modifyGasolineAvrageLiter(value) {
    if (value > 0 && value < 101) {
      setGasolineAvrageLiter(value);
    } else if (value <= 0) {
      setGasolineAvrageLiter("");
    }
  }
  function modifyDieselAvrageLiter(value) {
    if (value > 0 && value < 101) {
      setDieselAvrageLiter(value);
    } else if (value <= 0) {
      setDieselAvrageLiter("");
    }
  }
  return (
    <div className="page-handler">
      {currentPage === "Energia" ? (
        <Energy
          setCurrentPage={setCurrentPage}
          inputValues={inputValues}
          modifyInputValue={modifyInputValue}
          data={data}
          currentPage={currentPage}
        />
      ) : (
        <Travel
          setCurrentPage={setCurrentPage}
          inputValues={inputValues}
          modifyInputValue={modifyInputValue}
          data={data}
          dieselAvrageLiter={dieselAvrageLiter}
          gasolineAvrageLiter={gasolineAvrageLiter}
          modifyDieselAvrageLiter={modifyDieselAvrageLiter}
          modifyGasolineAvrageLiter={modifyGasolineAvrageLiter}
          currentPage={currentPage}
        />
      )} 
      <Total
        inputValues={inputValues}
        data={data}
        gasolineAvrageLiter={gasolineAvrageLiter}
        dieselAvrageLiter={dieselAvrageLiter}
        currentPage={currentPage}
      />
    </div>
  );
};

export default PageHandler;
