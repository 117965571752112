import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import NavBar from "../components/NavBar";

const Travel = ({
  setCurrentPage,
  inputValues,
  modifyInputValue,
  data,
  dieselAvrageLiter,
  gasolineAvrageLiter,
  modifyDieselAvrageLiter,
  modifyGasolineAvrageLiter,
  currentPage
}) => {
  const [selectValue, setSelectValue] = useState("Benzin");
  function travellPageCO2Calculator() {
    const trainMultiplier = data.OneTrainInputToCO2InKg;
    const aritMultiplier = data.OneAirInputToCO2InKG;
    const gasolineMultipiler = data.OneGasolinLiterToCO2;
    const dieselMutipiler = data.OneDieselLiterToCO2;

    const currentTrain = inputValues["Train"];
    const currentAir = inputValues["Air"];
    const currentGasoline = inputValues["Gasoline"];
    const currentDiesel = inputValues["Diesel"];

    return (
      currentAir * aritMultiplier +
      currentTrain * trainMultiplier +
      (currentGasoline / 100) * gasolineMultipiler * gasolineAvrageLiter +
      (currentDiesel / 100) * dieselMutipiler * dieselAvrageLiter
    );
  }
  return (
    <div className="left">
      <h1 className="main-title">Karbonlábnyom kalkulátor</h1>
      <NavBar currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <hr className="nav-hr" />
      <div className="dynamic-page">
        <h1 className="page-title">Autós közlekedés</h1>
        <p className="dynamic-page-input-title">ÜZEMANYAG TÍPUSA</p>
        <select onChange={(e) => setSelectValue(e.target.value)}>
          <option>Benzin</option>
          <option>Dízel</option>
        </select>
        {selectValue === "Benzin" ? (
          <div className="electricyt-page-input-contanier">
            <div className="electricity-page-input-box">
              <p className="dynamic-page-input-title">MEGTETT KILOMÉTER</p>
              <div className="electricity-page-input-row">
                <input
                  type="number"
                  value={inputValues["Gasoline"]}
                  onChange={(e) => modifyInputValue("Gasoline", e.target.value)}
                />
                <p className="dynamic-page-input-end">km</p>
              </div>
            </div>
            <div className="electricity-page-input-box">
              <p className="dynamic-page-input-title">ÁTLAG FOGYASZTÁS</p>
              <div className="electricity-page-input-row">
                <input
                  type="number"
                  value={gasolineAvrageLiter}
                  onChange={(e) => modifyGasolineAvrageLiter(e.target.value)}
                />
                <p className="dynamic-page-input-end">liter</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="electricyt-page-input-contanier">
            <div className="electricity-page-input-box">
              <p className="dynamic-page-input-title">MEGTETT KILÓMÉTER</p>
              <div className="electricity-page-input-row">
                <input
                  type="number"
                  value={inputValues["Diesel"]}
                  onChange={(e) => modifyInputValue("Diesel", e.target.value)}
                />
                <p className="dynamic-page-input-end">km</p>
              </div>
            </div>
            <div className="electricity-page-input-box">
              <p className="dynamic-page-input-title">ÁTLAG FOGYASZTÁS</p>
              <div className="electricity-page-input-row">
                <input
                  type="number"
                  value={dieselAvrageLiter}
                  onChange={(e) => modifyDieselAvrageLiter(e.target.value)}
                />
                <p className="dynamic-page-input-end">liter</p>
              </div>
            </div>
          </div>
        )}
        <h1 className="page-title">Vasúti- és légi közlekedés</h1>
        <div className="electricyt-page-input-contanier">
          <div className="electricity-page-input-box">
            <p className="dynamic-page-input-title">VASÚTI KÖZLEKEDÉS</p>
            <div className="electricity-page-input-row">
              <input
                type="number"
                value={inputValues["Train"]}
                onChange={(e) => modifyInputValue("Train", e.target.value)}
              />
              <p className="dynamic-page-input-end">km</p>
            </div>
          </div>
          <div className="electricity-page-input-box">
            <p className="dynamic-page-input-title">LÉGI KÖZLEKEDÉS</p>
            <div className="electricity-page-input-row">
              <input
                type="number"
                value={inputValues["Air"]}
                onChange={(e) => modifyInputValue("Air", e.target.value)}
              />
              <p className="dynamic-page-input-end">km</p>
            </div>
          </div>
        </div>
        <p className="dynamic-page-data">
          <span style={{ textAlign: "left" }}>CO₂:</span>
          <span style={{ fontWeight: "600", textAlign: "right" }}>
            {Math.ceil(travellPageCO2Calculator())} KG
          </span>
        </p>{" "}
        <p className="dynamic-page-data">
          <span style={{ textAlign: "left" }}>Megkötés Költsége: </span>
          <span style={{ fontWeight: "600", textAlign: "right" }}>
            {Math.ceil(travellPageCO2Calculator() * data.OneKgCO2ToHUF)} FT
          </span>
        </p>
      </div>
      <div className="page-switching-buttons">
        <button onClick={() => setCurrentPage("Energia")}>
          <IoIosArrowBack />
          Előző
        </button>
      </div>
    </div>
  );
};

export default Travel;
