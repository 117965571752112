import React from 'react'

const NavBar = ({currentPage,setCurrentPage}) => {
  return (
    <nav>
        <button className={currentPage === 'Energia'?'navbar-active-button':null} onClick={() => setCurrentPage('Energia')}>Energia</button>
        <button className={currentPage === 'Közlekedés'?'navbar-active-button':null} onClick={() => setCurrentPage('Közlekedés')}>Közlekedés</button>
    </nav>
  )
}

export default NavBar