import React from "react";
import TotalElement from "./TotalElement";

const Total = ({
  inputValues,
  data,
  gasolineAvrageLiter,
  dieselAvrageLiter,
  currentPage,
}) => {
  function totalSum() {
    let sum = 0;
    if (inputValues["Electricity"] > 0) {
      sum += inputValues["Electricity"] * data.OneElectricityInputToCO2InKG;
    }
    if (inputValues["Gas"] > 0) {
      sum += inputValues["Gas"] * data.OneGasInputToCO2InKG;
    }
    if (inputValues["Train"] > 0) {
      sum += inputValues["Train"] * data.OneTrainInputToCO2InKg;
    }
    if (inputValues["Air"] > 0) {
      sum += inputValues["Air"] * data.OneAirInputToCO2InKG;
    }
    if (inputValues["Gasoline"] > 0 && gasolineAvrageLiter > 0) {
      sum +=
        ((inputValues["Gasoline"] * data.OneGasolinLiterToCO2) / 100) *
        gasolineAvrageLiter;
    }
    if (inputValues["Diesel"] > 0 && dieselAvrageLiter > 0) {
      sum +=
        ((inputValues["Diesel"] * data.OneDieselLiterToCO2) / 100) *
        dieselAvrageLiter;
    }
    return sum;
  }
  return (
    <div className="total">
      <div className="total-contanier">
        <h1 className="main-title">Összegzés</h1>
        <p className="total-element">
          <span style={{ textAlign: "left" }}>Teljes Kibocsátás:</span>{" "}
          <span style={{ textAlign: "left" }}>{Math.ceil(totalSum())} KG</span>
        </p>
        <TotalElement
          inputValues={inputValues}
          inputValueName="Electricity"
          text="Elektromosság:"
          co2ToHUF={data.OneKgCO2ToHUF}
          inputToCo2={data.OneElectricityInputToCO2InKG}
        />
        <TotalElement
          inputValues={inputValues}
          inputValueName="Gas"
          text="Gáz:"
          co2ToHUF={data.OneKgCO2ToHUF}
          inputToCo2={data.OneGasInputToCO2InKG}
        />
        <TotalElement
          inputValues={inputValues}
          inputValueName="Train"
          text="Vasúti közlekedés:"
          co2ToHUF={data.OneKgCO2ToHUF}
          inputToCo2={data.OneTrainInputToCO2InKg}
        />
        <TotalElement
          inputValues={inputValues}
          inputValueName="Air"
          text="Légi közlekedés:"
          co2ToHUF={data.OneKgCO2ToHUF}
          inputToCo2={data.OneAirInputToCO2InKG}
        />
        <TotalElement
          inputValues={inputValues}
          inputValueName="Gasoline"
          text="Autós közlekedés:"
          co2ToHUF={data.OneKgCO2ToHUF}
          inputToCo2={(data.OneGasolinLiterToCO2 / 100) * gasolineAvrageLiter}
        />
        <TotalElement
          inputValues={inputValues}
          inputValueName="Diesel"
          text="Autós közlekedés:"
          co2ToHUF={data.OneKgCO2ToHUF}
          inputToCo2={(data.OneDieselLiterToCO2 / 100) * dieselAvrageLiter}
        />

        <p className="total-total">
          <span>Összesen:</span>{" "}
          <span>{Math.ceil(totalSum() * data.OneKgCO2ToHUF)} FT</span>
        </p>
          <button className="total-button">Kosárba</button>
      </div>
      <p className="total-element" style={{padding: "0rem 1.5rem"}}>
          <span style={{ textAlign: "left" }}>CO₂ semlegesítés ára:</span>{" "}
          <span style={{ textAlign: "left" }}>{data.OneKgCO2ToHUF} FT / KG</span>
        </p>
    </div>
  );
};

export default Total;
